<template>
	<v-sheet class="dashboard-main mt-4">
		<div>
			<v-flex class="d-flex justify-content-end align-center">
				<v-col class="me-2">
					<v-text-field
						hide-details
						outlined
						placeholder="Looker studio url.."
						v-model.trim="searchUrl"
					></v-text-field>
				</v-col>
				<v-col>
					<v-btn
						style="padding: 16px !important"
						class="white--text"
						:hide-details="hideDetails"
						depressed
						color="green"
						tile
						@click="applyUrl"
					>
						SAVE
					</v-btn>
				</v-col>
			</v-flex>
		</div>

		<v-row class="mt-2">
			<v-col md="12" class="px-0">
				<iframe
					allowfullscreen=""
					frameborder="0"
					height="620"
					:src="
						searchUrl
							? searchUrl
							: 'https://lookerstudio.google.com/embed/reporting/43250f73-c6e8-49e5-8c9b-5c5eb847179b/page/tzPdD'
					"
					style="border: 0"
					width="100%"
				></iframe>
			</v-col>
		</v-row>
	</v-sheet>
</template>
<script>
export default {
	name: "Analytics",
	data() {
		return {
			searchUrl: null,
		};
	},
	methods: {
		applyUrl() {
			this.$refs.myIframe.src = this.searchUrl;
		},
	},
};
</script>
